<template>
  <el-dialog
    title="發票設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="left"
      label-width="150px"
    >
      <el-form-item label="開放設置發票">
        <el-switch
          v-model="formData.openInvoice"
          class="switch-input"
          active-text="開啟"
          inactive-text="關閉"
        />
      </el-form-item>

      <el-form-item
        v-if="formData.openInvoice"
        label="第三方串接發票"
        prop="platform"
      >
        <el-select v-model="formData.platform">
          <el-option
            v-for="platform in invoicePlatformConfig"
            :key="platform.value"
            :label="platform.name"
            :value="platform.value"
          />
        </el-select>
      </el-form-item>

      <div v-if="formData.platform !== 'null' && formData.openInvoice">
        <el-form-item label="MerchantId" prop="merchantID">
          <el-input v-model="formData.merchantID" placeholder="請輸入" />
        </el-form-item>

        <el-form-item label="Hash Key" prop="hashKey">
          <el-input v-model="formData.hashKey" placeholder="請輸入" />
        </el-form-item>

        <el-form-item label="Hash IV" prop="hashIV">
          <el-input v-model="formData.hashIV" placeholder="請輸入" />
        </el-form-item>
      </div>

      <el-form-item v-if="formData.openInvoice" label="使用三聯發票">
        <el-switch
          v-model="enableOrderInvoiceB2B"
          class="switch-input"
          active-text="開啟"
          inactive-text="關閉"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="updateEcommerceConfig">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { UpdateEcommerceConfig } from '@/api/ecommerce/paramter'
import { invoicePlatformConfig } from '@/config/ecommerce'
import { noEmptyRules } from '@/validation/index'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/form'
export default {
  name: 'InvoiceDialog',
  props: {
    invoice: Object,
  },
  data: () => ({
    invoicePlatformConfig,
    enableOrderInvoiceB2B: false,
    enableOrderInvoiceApi: false,
    formData: {
      platform: 'ezpay',
      merchantID: '',
      hashKey: '',
      hashIV: '',
      openInvoice: false,
    },
    formRules: {
      merchantID: [noEmptyRules()],
      hashKey: [noEmptyRules()],
      hashIV: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },

  mounted () {
    this.syncData()
    console.log(this.invoice)
  },
  methods: {
    async updateEcommerceConfig () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      let enableOrderInvoiceApiConfig
      let enableOrderInvoiceApi = false
      if (this.formData.platform !== 'null' && this.formData.openInvoice) {
        enableOrderInvoiceApi = true
        enableOrderInvoiceApiConfig = {
          platform: this.formData.platform,
          merchantID: this.formData.merchantID,
          hashKey: this.formData.hashKey,
          hashIV: this.formData.hashIV,
        }
      }

      const [res, err] = await UpdateEcommerceConfig({
        shopId: this.shop,
        enableOrderInvoice: this.formData.openInvoice,
        enableOrderInvoiceApi,
        enableOrderInvoiceApiConfig,
        enableOrderInvoiceB2B: this.enableOrderInvoiceB2B,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新發票設定成功！')
      this.$emit('updated')
      console.log(res)
      this.$emit('close')
    },

    syncData () {
      this.enableOrderInvoiceApi = this.invoice.enableOrderInvoiceApi
      this.enableOrderInvoiceB2B = this.invoice.enableOrderInvoiceB2B
      this.formData.openInvoice = this.invoice.enableOrderInvoice

      if (!this.invoice.enableOrderInvoiceApi) {
        this.formData.platform = 'null'
        return
      }

      this.formData.platform = this.invoice.enableOrderInvoiceApiConfig.platform
      this.formData.merchantID = this.invoice.enableOrderInvoiceApiConfig.merchantID
      this.formData.hashKey = this.invoice.enableOrderInvoiceApiConfig.hashKey
      this.formData.hashIV = this.invoice.enableOrderInvoiceApiConfig.hashIV
    },
  },
}
</script>

<style></style>
